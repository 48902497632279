import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';

import { receiveProducts } from '@ttstr/actions';
import { Container, LoadingSpinner, Masonry, useIntl, Video } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';

// const images = [
//   require('../public/zwoelfk-slider/12k_Summerdrop_1-min.jpg'),
//   require('../public/zwoelfk-slider/12k_Summerdrop_2-min.jpg'),
//   require('../public/zwoelfk-slider/12k_Summerdrop_3-min.jpg'),
//   require('../public/zwoelfk-slider/12k_Summerdrop_4-min.jpg'),
// ];

// const mobileImages = [
//   require('../public/zwoelfk-slider/12k_Slideshow_mobil_1-min.jpg'),
//   require('../public/zwoelfk-slider/12k_Slideshow_mobil_2-min.jpg'),
//   require('../public/zwoelfk-slider/12k_Slideshow_mobil_3-min.jpg'),
//   require('../public/zwoelfk-slider/12k_Slideshow_mobil_4-min.jpg'),
// ];

// const links = [
//   '?categoryId=3003#product-listing',
//   '?categoryId=3003#product-listing',
//   '?categoryId=3003#product-listing',
//   '?categoryId=3003#product-listing',
// ];

const ProductListing: React.FC = () => {
  const { t } = useTranslation();
  const { loading, products } = useShallowEqualSelector(mapStateToProps);
  const { receiveProducts } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    receiveProducts();
  }, [language]);

  return (
    <article>
      <Container fluid className="container list-page p-0 bg-light">
        <Helmet>
          <title>{t(`PRODUCTS.TITLE`)}</title>
          <body className="product-listing-page" />
        </Helmet>
        {/* <h1 className="superhead mt-5 mb-5">Store</h1> */}

        {loading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.PRODUCTS`)} />
        ) : products.length === 0 ? ( // if no products
          <Alert color="info m-0">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show products
          <Masonry
            id="product-listing"
            className="product-listing p-0"
            products={products.filter((p) => !p.tag_ids.includes(8736))}
            filters="off"
            showSubtitle
            rowClassName="row no-gutters"
            columnClassName="col-sm-12 col-md-4 col-lg-4"
            //            columnClassName="col-12 col-sm-6 col-md-4 col-xl-3"

            itemProps={{ overlay: true }}
            noResultsClassName="container-fluid my-4 text-info text-center"
          />
        )}
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { products, loading } = state.Products;
  return {
    products,
    loading,
  };
};

const mapDispatchToProps = {
  receiveProducts,
};

export default React.memo(ProductListing);
