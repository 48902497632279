import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import { Product } from '@ttstr/api';
import { Container, VariantChooser, NativeSlider, useShopConfig, useProductDetailLink } from '@ttstr/components';
import PreviewImage from '@ttstr/components/Sushi/PreviewImage';

interface OwnProps {
  readonly product: Product;
}

type Props = Readonly<OwnProps>;

const ProductDetail: React.FC<Props> = ({ product }) => {
  const { t } = useTranslation();
  const { canonicalUrl } = useShopConfig();
  const productDetailLink = useProductDetailLink();

  const heroImageAsArray = product.hero_image.url ? [product.hero_image.url] : [];
  const images = React.useMemo(() => [...heroImageAsArray, ...product.gallery_image_urls.map((i) => i.original)], [
    product,
  ]);
  const previewImage = product.image_thumb;
  // const heroThumbAsArray = product.hero_image.thumb.url ? [product.hero_image.thumb.url] : [];
  // const thumbs = React.useMemo(() => [...heroThumbAsArray, ...product.gallery_image_urls.map((i) => i.thumb)], [
  //   product,
  // ]);

  return (
    <article className="container product-detail mt-5" itemScope itemType="http://schema.org/Product">
      <Helmet>
        <title>
          {product.supertitle && product.supertitle + ' - '}
          {product.title}
          {product.subtitle && ' - ' + product.subtitle}
        </title>
        <link rel="canonical" href={productDetailLink(product, { canonicalUrl })} />
      </Helmet>
      <meta itemProp="sku" content={String(product.id)} />

      {Boolean(images?.length) && (
        <header className="product-header d-md-none">
          <NativeSlider images={images} previewImage={previewImage} />
        </header>
      )}

      <section className="product-content">
        <Container fluid className="detail-page">
          <Row>
            <Col md={6} className="px-0 pr-md-0 product-images">
              {Boolean(images?.length) && (
                <div className="d-none d-md-block">
                  {images.map((image, index) => (
                    <PreviewImage
                      key={image}
                      src={image}
                      previewSrc={previewImage}
                      alt={t(`PRODUCT.IMAGE_INDEX`, { index: index + 1 })}
                      className="w-100"
                      itemProp="image"
                      loading="lazy"
                    />
                  ))}
                </div>
              )}
            </Col>
            <Col md={6} xl={{ size: 4, offset: 1 }} className="product-info-wrapper">
              <section className="product-info sticky-top">
                <header className="mb-2 mt-5 text-left">
                  {product.supertitle && <span className="text-muted">{product.supertitle}</span>}
                  <h1 className="display-5">
                    <span itemProp="name">{product.title}</span>
                    <small>{product.subtitle}</small>
                  </h1>
                </header>
                <VariantChooser product={product} />
                {/* <VariantListing product={product} /> */}
                {product.description && (
                  <>
                    <h2 className="product-title">{t(`PRODUCT.INFORMATION`)}</h2>
                    <div
                      dangerouslySetInnerHTML={{ __html: product.description }}
                      itemProp="description"
                      className="product-description"
                    />
                  </>
                )}
              </section>
            </Col>
          </Row>
        </Container>
      </section>
    </article>
  );
};

export default React.memo(ProductDetail);
